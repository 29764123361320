import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Impressum = () => {
  return (
    <Layout>
      <SEO title="Impressum " />
      <main className="page">
        <h1>Impressum</h1>
        <section>
          <div>
            <div>
              <div>
                <p>
                  <strong>Diensteanbieter</strong>
                  <br />
                  Felix Christian Buss
                  <br />
                  Große-Leege-Str. 43A
                  <br />
                  13055 Berlin
                  <br />
                  Deutschland
                  <br />
                  <br />
                  <strong>Kontaktmöglichkeiten</strong>
                  <br />
                  E-Mail-Adresse: info@ideetipp.de
                  <br />
                  Telefon: +49 152 226 794 79
                  <br />
                  <br />
                  <strong>
                    Journalistisch-redaktionelle Angebote
                    <br />
                  </strong>
                  Inhaltlich verantwortlich: Felix Christian Buss
                  <br />
                  <br />
                  <strong>
                    Audiovisuelle Mediendienste
                    <br />
                  </strong>
                  Sitzland: Deutschland
                  <br />
                  Zuständige Regulierungs-, bzw. Aufsichtsbehörde: Medienanstalt
                  Berlin-Brandenburg (mabb),{" "}
                  <a href="https://www.mabb.de">www.mabb.de</a>
                  <br />
                  <br />
                  <strong>
                    Online-Streitbeilegung (OS)
                    <br />
                  </strong>
                  Online-Streitbeilegung: Die Europäische Kommission stellt eine
                  Plattform zur Online-Streitbeilegung (OS) bereit, die Sie
                  unter{" "}
                  <a href="https://ec.europa.eu/consumers/odr/" target="_blank">
                    https://ec.europa.eu/consumers/odr/
                  </a>{" "}
                  finden. Verbraucher haben die Möglichkeit, diese Plattform für
                  die Beilegung ihrer Streitigkeiten zu nutzen.
                  <br />
                  <br />
                  <strong>
                    Social Media und andere Onlinepräsenzen
                    <br />
                  </strong>
                  Dieses Impressum gilt auch für die folgenden
                  Social-Media-Präsenzen und Onlineprofile:
                  <br />
                  <br />
                  <strong>
                    Haftungs- und Schutzrechtshinweise
                    <br />
                    Haftungsausschluss
                  </strong>
                  : Die Inhalte dieses Onlineangebotes wurden sorgfältig und
                  nach unserem aktuellen Kenntnisstand erstellt, dienen jedoch
                  nur der Information und entfalten keine rechtlich bindende
                  Wirkung, sofern es sich nicht um gesetzlich verpflichtende
                  Informationen (z.B. das Impressum, die Datenschutzerklärung,
                  AGB oder verpflichtende Belehrungen von Verbrauchern) handelt.
                  Wir behalten uns vor, die Inhalte vollständig oder teilweise
                  zu ändern oder zu löschen, soweit vertragliche Verpflichtungen
                  unberührt bleiben. Alle Angebote sind freibleibend und
                  unverbindlich.
                  <br />
                  <strong>Links auf fremde Webseiten</strong>: Die Inhalte
                  fremder Webseiten, auf die wir direkt oder indirekt verweisen,
                  liegen außerhalb unseres Verantwortungsbereiches und wir
                  machen sie uns nicht zu Eigen. Für alle Inhalte und Nachteile,
                  die aus der Nutzung der in den verlinkten Webseiten
                  aufrufbaren Informationen entstehen, übernehmen wir keine
                  Verantwortung.
                  <br />
                  <strong>Urheberrechte und Markenrechte</strong>: Alle auf
                  dieser Website dargestellten Inhalte, wie Texte, Fotografien,
                  Grafiken, Marken und Warenzeichen sind durch die jeweiligen
                  Schutzrechte (Urheberrechte, Markenrechte) geschützt. Die
                  Verwendung, Vervielfältigung usw. unterliegen unseren Rechten
                  oder den Rechten der jeweiligen Urheber bzw. Rechteinhaber.
                  <br />
                  <strong>Hinweise auf Rechtsverstöße</strong>: Sollten Sie
                  innerhalb unseres Internetauftritts Rechtsverstöße bemerken,
                  bitten wir Sie uns auf diese hinzuweisen. Wir werden
                  rechtswidrige Inhalte und Links nach Kenntnisnahme
                  unverzüglich entfernen.
                  <br />
                  <strong>Bildnachweise</strong>
                  <br />
                  Bildquellen und Urheberrechtshinweise:
                  <br />
                  <a href="https://unsplash.com" target="_blank">
                    https://unsplash.com/
                  </a>
                  <br />
                  <br />
                  <a
                    href="https://datenschutz-generator.de/?l=de"
                    target="_blank"
                  >
                    Erstellt mit kostenlosem Datenschutz-Generator.de von Dr.
                    Thomas Schwenke
                  </a>
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Impressum
